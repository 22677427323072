import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

// State
export type ConfigState = Config;

// Initial State
export const initialState: ConfigState = {} as ConfigState;

// Reducers
const set: CaseReducer<ConfigState, PayloadAction<ConfigDto>> = (_state, action): Config | never => {
  if (action.payload) {
    const dto = action.payload;
    return {
      ...dto,
      siteAccess: dto.current_siteaccess || null,
      trackingClients: dto.tracking_clients || {},
    };
  }
};

// Slice
const slice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    set,
  },
});

// Export: Actions
export const actions = slice.actions;

// Export: Reducer
export default slice.reducer;

// Selectors
const selectSelf = (state: ConfigState) => state;
export const selectors = {
  self: selectSelf,
  apiPrefix: (state: ConfigState) => state.apiPrefix,
  appDocTitle: (state: ConfigState) => state.appDocTitle,
  appName: (state: ConfigState) => state.appName,
  backendUrl: (state: ConfigState) => state.backendUrl,
  cssUrl: (state: ConfigState) => state.cssUrl,
  cssPatcherUrl: (state: ConfigState) => state.cssPatcherUrl,
  customJs: (state: ConfigState) => state.customJs,
  defaultLanguage: (state: ConfigState) => state.defaultLanguage,
  defaultPageUserGroups: (state: ConfigState) => state.defaultPageUserGroups,
  fileBackendUrl: (state: ConfigState) => state.fileBackendUrl,
  googleAnalyticsID: (state: ConfigState) => state.googleAnalyticsID,
  isAdminModeDisabled: (state: ConfigState) => state.adminModeDisabled,
  isPublicMode: (state: ConfigState) => state.publicMode,
  languages: (state: ConfigState) => state.languages,
  logoUrl: (state: ConfigState) => state.logoUrl,
  matomoSiteId: (state: ConfigState) => state.matomoSiteId,
  matomoUrl: (state: ConfigState) => state.matomoUrl,
  notificationTimeout: (state: ConfigState) => state.notificationTimeout,
  pagePrefix: (state: ConfigState) => state.pagePrefix,
  symfonyBackendUrl: (state: ConfigState) => state.symfonyBackendUrl,
  myFilesEnabled: (state: ConfigState) => state.myFilesEnabled,
  myFilesUrl: (state: ConfigState) => state.myFilesUrl,
  siteAccess: (state: ConfigState) => state.siteAccess,
  trackingClients: (state: ConfigState) => state.trackingClients,
  fallbackForbiddenUrl: (state: ConfigState) => state.fallbackForbiddenUrl,
  defaultMediaLibraryRealm: (state: ConfigState) => state.defaultMediaLibraryRealm,
  isMediaLibraryMaintenance: (state: ConfigState) => state.isMediaLibraryMaintenance,
  logoutUrl: (state: ConfigState) => state.logoutUrl,
};
