import { Component, ReactNode } from 'react';
import { Provider } from 'react-redux';
import { Store } from 'redux';

import type { RootState } from '$store';
import getStore, { initStore } from '$store';
import AppContainer from '~components/app/AppContainer';
import CustomJsExecutor from '~components/CustomJsExecutor';
import { InitializationLoader } from '~components/InitializationLoader';
import { redirectToLogin, verifyLoginStatus } from '~services/api/calls/user';
import { useAppLogin } from '~services/env';
import { getLangFromLocation } from '~services/location-helpers';
import log from '~services/log';
import { getSiteInfo } from '~services/siteInfo';
import appendCss from '~utils/appendCss';
import { refreshFavicon } from '~utils/favicon';
import ResponsiveHelper from './components/ResponsiveHelper';
import { UserActions } from './store/actions';
import {
  getConfigBackendUrl,
  getConfigCssPatcherUrl,
  getConfigCssUrl,
  getConfigIsPublicMode,
  getConfigSiteAccess,
} from './store/getter';
import {
  selectConfigBackendUrl,
  selectSessionName,
  selectSessionServerSession,
  selectUserId,
  selectUserIsValid,
} from './store/selectors';
import { startRouting } from './store/slices/router';
import { init } from './store/thunks/init';

interface IAuthProps extends Object {}

interface IAuthState {
  isReadyForInit: boolean;
  hasInitFailed: boolean;
}

export class Auth extends Component<IAuthProps, IAuthState> {
  constructor(props: IAuthProps) {
    super(props);
    this.state = {
      isReadyForInit: false,
      hasInitFailed: false,
    };
  }

  private requestOauthToken = () => {
    if (window.ebm?.instances?.oauthTokenManager) {
      const { oauthTokenManager } = window.ebm.instances;
      oauthTokenManager.getAccessToken();
    }
  };

  private isUserValid = (state: RootState): boolean => {
    const userId = selectUserId(state);
    if (userId) {
      const userValid = selectUserIsValid(state);
      if (userValid !== null && userValid === false) {
        // if user is non-anonymous and his/her profile is not valid, redirect to the edit form
        log('User profile not valid - redirecting to the edit form', { userId, isValid: userValid }, 'error');
        const userEditUrl = `${selectConfigBackendUrl(state)}/user/${userId}/edit`;
        window.location.href = userEditUrl;
        return false;
      } else {
        return true;
      }
    } else {
      throw new Error('No userId');
    }
  };

  private triggerSetConfigUserInfo = (store: Store, user: UserInfoDto) => {
    setTimeout(() => {
      store.dispatch(UserActions.set(user));
    });
  };

  private triggerLoggedIn = store => {
    setTimeout(() => {
      store.dispatch(init());
    });
  };

  private init = () => {
    this.setState({ isReadyForInit: true });
  };

  private initFailed = () => {
    this.setState({ isReadyForInit: false, hasInitFailed: true });
  };

  public render(): ReactNode {
    if (this.state.isReadyForInit) {
      return (
        <Provider store={getStore()}>
          <AppContainer />
          <CustomJsExecutor />
          <ResponsiveHelper />
        </Provider>
      );
    } else if (this.state.hasInitFailed) {
      return <p>Loading configuration failed!</p>;
    } else {
      return <InitializationLoader />;
    }
  }

  public async componentDidMount() {
    log('Request Application context…');
    try {
      // Read current language (in url /en/ or /de/)
      const lang = getLangFromLocation();

      const siteInfo = await getSiteInfo();
      log('Application site info loaded! Initializing...', siteInfo);

      // Re-Create redux store with siteinfo data
      const store = initStore(lang, siteInfo);

      // Apply css theme
      const css = getConfigCssUrl();
      log('Apply CSS...', css);
      appendCss(css);

      // Apply css patcher
      const csspatcher = getConfigCssPatcherUrl();
      if (csspatcher !== '') {
        log('Apply CSS patcher...', csspatcher);
        appendCss(csspatcher);
      }

      // Dynamic Favicon
      const siteAccess = getConfigSiteAccess();
      if (siteAccess) {
        refreshFavicon(siteAccess.url, siteAccess.theme);
      }

      // indicates if the app should use it's own build in login
      if (useAppLogin) {
        const state = store.getState();
        const serverSession = selectSessionServerSession(state);
        const userId = selectUserId(state);
        if (userId === 0 && serverSession !== null && serverSession.current_user.uid !== 0) {
          // user data is set in session (state cookie) but not yet in the app config
          log('Set userInfo in config from session', serverSession.current_user);
          this.triggerSetConfigUserInfo(store, serverSession.current_user as UserInfoDto);
        }
        if (selectSessionName(state)) {
          this.triggerLoggedIn(store);
        }
        this.init();
      } else {
        if (!getConfigIsPublicMode()) {
          log('App is in private mode!', null, 'warning');
          log('Validate login status...');
          const isLoginVerified = await verifyLoginStatus();
          if (isLoginVerified) {
            log('Login status is valid!');
            const isUserValid = this.isUserValid(store.getState());
            if (isUserValid) {
              log('Start the app!');
              this.triggerLoggedIn(store);
              this.init();
            }
          } else {
            log('Login status is invalid!', { isLoginVerified }, 'warning');
            redirectToLogin(getConfigBackendUrl());
          }
        } else {
          log('App is not private!');
          log('Start the app!');
          this.triggerLoggedIn(store);
          this.init();
        }
      }
    } catch (e) {
      log('Error initializing!', e, 'error');
      this.initFailed();
    }
  }

  public componentDidUpdate() {
    if (this.state.isReadyForInit) {
      // start app routing
      setTimeout(() => startRouting());

      // request ebm oauth token
      this.requestOauthToken();
    }
  }
}
