/**
 * Appends an CSS file by its URL to the `document.head`
 * @param cssUrl String - URL to CSS file
 */
export default function appendCssToDocumentHead(cssUrl: string) {
  if (cssUrl === '') return;
  const cssEl = document.createElement('style');
  cssEl.innerHTML = `@import url('${cssUrl}');`;
  window.document.head.appendChild(cssEl);
}
